import { ApiBaseUrl } from "../Utils/urls";

//fetch property details
export const getPropertyDetails=(
  setLoading,
  propId,
  adminId,
  accesstoken,
  setPropResp,
  setPropertyInfo,
  setpropAmenities,
  setFloorPlans,
  setpropImage,
  setAboutProp,
  setProjectSpec,
  setPropertyName,
  setPropertyType,
  setBuilderName,
  setselectedCityId,
  setSelectedlocationId,
  setLocations,
  cityLocationData,
  setBuilderLogo,
  setPropSaleType,
  setPropPhase,
  setTokenInfoState,
  setDocumentsList,
  setFloorNo,
  setblockNo,
  setUnitNo,
  setDirection,
  setConfig,
  setPropNameOnly
)=>{

  setLoading &&  setLoading(true)
  fetch(`${ApiBaseUrl}/fr-admin/get-property-details?property_id=${propId}&admin_id=${adminId}`,{
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': accesstoken
    }
  })
    .then(response => {
    
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
     
      return response.json();
    })
    .then(resdata => {
     
     //console.log('Response from server:', resdata);
     
        if(resdata.success)
        {
          setLoading &&  setLoading(false)
          setPropResp &&  setPropResp(resdata.data)
          setPropertyInfo &&  setPropertyInfo(resdata.data.property_info)
         setpropAmenities && setpropAmenities(resdata.data.property_info.amenities)
         setFloorPlans && setFloorPlans(resdata.data.property_info.floor_plan)
         if(resdata.data.property_info?.property_images)
          {
            setpropImage && setpropImage(resdata.data.property_info.property_images)
          }
         
         setAboutProp && setAboutProp(resdata.data.property_info.property_description)

         setPropertyName && setPropertyName(resdata.data.property_info.property_name)

         setPropertyType && setPropertyType(resdata.data.property_info.property_type)
         setBuilderName && setBuilderName(resdata.data.property_info.builder_name)
         setselectedCityId && setselectedCityId(resdata.data.property_info.fk_city_id)
///test
         setSelectedlocationId && setSelectedlocationId(resdata.data.property_info.fk_location_id)
         setBuilderLogo  && setBuilderLogo(resdata.data.property_info.builder_logo)

         setPropSaleType && setPropSaleType(resdata.data.property_info.property_sale_type)
         setPropPhase && setPropPhase(resdata.data.property_info.property_phase)
if(resdata.data.property_info?.property_documents?.length>0)
  {
    setDocumentsList && setDocumentsList(resdata.data.property_info.property_documents)
}
else{
setDocumentsList && setDocumentsList(    [{
  "category": "",
  "documents": [
      {
          "docs": [],
          "title": "",
          "description": null
      }
  ]
}]
)
}

setPropNameOnly && setPropNameOnly(resdata.data.property_info.property_name_only)

setFloorNo && setFloorNo(resdata.data.property_info.floor_no)      

setblockNo && setblockNo(resdata.data.property_info.block_no)

setConfig && setConfig(resdata.data.property_info.configuration)

setDirection && setDirection(resdata.data.property_info.direction)
     
setUnitNo && setUnitNo(resdata.data.property_info.unit_no)

         if(cityLocationData)
          {
            const selectedCity = cityLocationData.find(city => city.city_id === resdata.data.property_info.fk_city_id);
         
         
            setLocations && setLocations(selectedCity ? selectedCity.locations : []);
         
          }
        



         //test




         if (resdata && resdata.data.property_info.project_specs?.length > 0) {
          setProjectSpec && setProjectSpec(resdata.data.property_info.project_specs)
        } else {
          setProjectSpec && setProjectSpec([""]);
        }


         if (resdata && resdata.data.property_info?.floor_plan?.length > 0) {
          setFloorPlans &&  setFloorPlans(resdata.data.property_info.floor_plan);
        } else {
          setFloorPlans && setFloorPlans([{ title: '', value: '', description: '', floor_image: {} }]);
        }
        }



        const tokenInfo = {
          admin_id: adminId,
          property_id: propId,
          price_per_share: resdata.data.property_info.price_per_share || null,
          property_price: resdata.data.property_info.property_price || null,
          total_shares: resdata.data.property_info.total_shares || null,
          lock_in_period: resdata.data.property_info.lock_in_period || null,
          target_IRR: resdata.data.property_info.target_IRR || null,
          gross_yield: resdata.data.property_info.gross_yield || null,
          capital_gain: resdata.data.property_info.capital_gain || null,
          escrow_name: resdata.data.property_info.escrow_name || "",
          limit_per_share: resdata.data.property_info.limit_per_share || null,
          base_currency_id: resdata.data.property_info.base_currency_id || null,
          escrow_id:resdata.data.property_info.escrow_id || "",
        };



        setTokenInfoState && setTokenInfoState(tokenInfo);



    })
    .catch(error => {
     
      console.error('error details:', error);
    });
}

export const getCityLocation=(setCityLocationData)=>{
    fetch(`${ApiBaseUrl}/fr-admin/fetch-properties-default-filters`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
       
      }
     
    }
  )
      .then(response => {
      
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
       
        return response.json();
      })
      .then(resdata => {
       
       //console.log('Response from server:', resdata);
      if(resdata.success)
         {
            setCityLocationData(resdata.data.place_filters)
         }
  
      })
      .catch(error => {
       
        console.error('error details:', error);
      });
}


export const AddCity=(
    setCityLoading,
    accessToken,
    adminId,
    cityName,
    locationList,
    cityIcon,
    setCityAlertMsg,
    setCityAdded,
    setShowAddCityPopUp,
    setCityName,
    setLocationList,
    setCityAddErr,
    setCityIcon,
    fetchCityLocation
)=>{

  
 
    setCityLoading(true)
    fetch(`${ApiBaseUrl}/fr-admin/add-city-location`,
       {
         method: 'POST',
         headers: {
           'Content-Type': 'application/json',
           'Authorization': accessToken
         },
         body: JSON.stringify({
          admin_id:adminId,
          city_name:cityName,
          locations:locationList,
          city_icon:cityIcon,
    
       })
       }
     )
         .then(response => {
         
          //  if (!response.ok) {
          //    throw new Error('Network response was not ok');
          //  }
          
           return response.json();
         })
         .then(resdata => {
          
          //console.log('Response from server:', resdata);
          setCityLoading(false)
             if(resdata.success)
             {

                setCityAlertMsg(resdata.data)
                setCityAdded(true)
                fetchCityLocation()
                setTimeout(() => {
                   setCityAlertMsg("")
                   setCityAdded(false)
                   setShowAddCityPopUp(false)
    
                   setCityName("")
                      setLocationList([])
                      setCityIcon({})
                }, 3000);
             }
             else if(!resdata.success)
                {
                   setCityAlertMsg(resdata.data)
                   setCityAddErr(true)
                   setTimeout(() => {
                      setCityAlertMsg("")
                      setCityAddErr(false)
    
                      // setCityName("")
                      // setLocationList([])
                      // setCityAddErr({})
                   }, 3000);
                }
     
         })
         .catch(error => {
          
           console.error('error details:', error);
         });
    
    }


    //add location
    

    export const AddLocationTodB=(
      adminId,
      selectedCityId,
      selectedCityLabel,
      locationList,
      selectedCityBucket,
      selectedCityIconKey,
      setAddLocLoading,
      accessToken,
      setLocAddAlertMsg,
      setlocationAdded,
      setShowAddLocPopup,
      fetchCityLocation,
      setLocationList,
      setLocAddErr
    )=>{
  
      let addLocationBody={
         admin_id:adminId,
         city_id:selectedCityId,
         city_name:selectedCityLabel,
         locations:locationList,
         city_icon:{
            Bucket:selectedCityBucket,
            Key:selectedCityIconKey
         }
      
      }
      
      
      setAddLocLoading(true)
         fetch(`${ApiBaseUrl}/fr-admin/add-city-location`,
            {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
                'Authorization': accessToken
              },
              body: JSON.stringify(addLocationBody)
            }
          )
              .then(response => {
                return response.json();
              })
              .then(resdata => {
               
               console.log('Response from server:', resdata);
               setAddLocLoading(false)
               
                  if(resdata.success)
                  {
                 
      
                  setLocAddAlertMsg(resdata.data)
                 
                  setlocationAdded(true)
                  setShowAddLocPopup(false)
                  setTimeout(() => {
                     setLocAddAlertMsg("")
                     setlocationAdded(false)
                     fetchCityLocation()
      
                  //    setCityName("")
                        setLocationList([])
                  //       setCityIcon({})
                 }, 3000);
                  }
                  else if(!resdata.success)
                     {
                        setLocAddAlertMsg(resdata.data)
      
                     
                        setLocAddErr(true)
                     setTimeout(() => {
                        setLocAddAlertMsg("")
                        setLocAddErr(false)
         
                      
                        }, 3000);
                     }
          
              })
              .catch(error => {
               
                console.error('error details:', error);
              });
      }
      

      //add property basics
      export const AddPropertyBasics=(
        adminId,
        propertyName,
        propertyType,
        builderName,
        builderLogo,
        propSaleType,
        propPhase,
        selectedCityId,
        selectedlocationId,
        setpropBaseAddLoading,
        accessToken,
        setPropBaseAlertMsg,
        setPropBaseAdded,
        setPropBaseErr,
        propertyId,
        setPropertyId,
        propNameOnly,
        floorNo,
        blockNo,
        unitNo,
        direction,
        configuration
      )=>{
        let propertyObj={
           admin_id:adminId,
           property_id:propertyId, 
           property_name:propertyName,
           property_type:propertyType, 
           builder_name :builderName,
           builder_logo:builderLogo,
           property_sale_type:propSaleType,
           property_phase:propPhase,
           city_id:parseFloat(selectedCityId),
           location_id:parseFloat(selectedlocationId),
         property_name_only:propNameOnly,
         unit_no:unitNo,
         block_no:blockNo,
         floor_no:floorNo,
         configuration:configuration,
         direction:direction
        }
        
        
        setpropBaseAddLoading(true)
        fetch(`${ApiBaseUrl}/fr-admin/add-property`,
           {
             method: 'POST',
             headers: {
               'Content-Type': 'application/json',
               'Authorization': accessToken
             },
             body: JSON.stringify(propertyObj)
           }
         )
             .then(response => {
               return response.json();
             })
             .then(resdata => {
              
              console.log('Response from server:', resdata);
           
              setpropBaseAddLoading(false)
                 if(resdata.success)
                 {
                
        
                    setPropBaseAlertMsg(resdata.data.msg)
                    setPropertyId &&    setPropertyId(resdata.data.property_id)
                    localStorage.setItem("propertyId",resdata.data.property_id)

                    setPropBaseAdded(true)
                    setTimeout(() => {
                       setPropBaseAdded(false)
                       setPropBaseAlertMsg("")
                     
                    }, 3000);
                
                 }
                 else if(!resdata.success)
                    {
                       setPropBaseAlertMsg(resdata.data)
                       setPropBaseErr(true)
                       setTimeout(() => {
                          setPropBaseErr(false)
                          setPropBaseAlertMsg("")
        
                       }, 3000);
                    }
         
             })
             .catch(error => {
              
               console.error('error details:', error);
             });
        }
        

        //get currency
       export const fetchAlllCurrencies=(setCurrencies)=>{
         fetch(`${ApiBaseUrl}/fr-admin/get-all-currencies`,
         {
           method: 'GET',
           headers: {
             'Content-Type': 'application/json',
           },
         
         }
       )
           .then(response => {
             return response.json();
           })
           .then(resdata => {
            
            //console.log('Response from server:', resdata);
          
            
               if(resdata.success)
               {
              
                 setCurrencies(resdata.data)
               
              
              
               }
               else if(!resdata.success)
                 {}
       
           })
           .catch(error => {
            
             console.error('error details:', error);
           });
     }



     //add property amenities


    export const AddPropertyAmenities=(
      seAddPropAmenitiestLoading,
      accessToken,
      adminId,
      propertyId,
      addedAmenityIds,
      setAmenitiesAdded,
      setAmenAddAlertMsg,
      setAmenAddErr
    )=>{
      seAddPropAmenitiestLoading(true)
      fetch(`${ApiBaseUrl}/fr-admin/update-property-amenities`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': accessToken
        },
        body: JSON.stringify({
          "admin_id":adminId,
          "property_id": Number(propertyId),
          "amenities": addedAmenityIds
      })
      }
    )
        .then(response => {
        
         
          return response.json();
        })
        .then(resdata => {
         
         console.log('Response from server:', resdata);
         seAddPropAmenitiestLoading(false)
            if(resdata.success)
            {
             
              setAmenitiesAdded(true)
              setAmenAddAlertMsg(resdata.data)
              setTimeout(() => {
                setAmenitiesAdded(false)
                setAmenAddAlertMsg("")
              }, 3000);
            }
                else if(!resdata.success)
                  {
                    setAmenAddErr(true)
                    setAmenAddAlertMsg(resdata.data)
                    setTimeout(() => {
                      setAmenAddErr(false)
                      setAmenAddAlertMsg("")
                    }, 3000);
                  }
        })
        .catch(error => {
         
          console.error('error details:', error);
        });
    }
    