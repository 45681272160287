import React, { useEffect, useState } from 'react'
import AdminContainer from '../AdminContainer/AdminContainer'
import "./adminlist-style.css"
import { MdOutlineFileDownload } from 'react-icons/md'
import { IoIosSearch } from 'react-icons/io'
import { Link } from 'react-router-dom'
import { BsThreeDots } from 'react-icons/bs'
import enableIcon from "../../Assets/Icons/verified.svg"
import AdminCard from './AdminCard'
import { ApiBaseUrl } from '../../Utils/urls'
import useDebounce from '../../Utils/Debounce'
import { BeatLoader } from 'react-spinners'
import loader from "../../Assets/Icons/Loader.gif"
import { FetchAdminList, getAdminProfileDetails } from '../../Api/AdminApi'


export default function AdminList() {
  const [isLoading,setLoading]=useState(false)
 

const adminId=localStorage.getItem("adminId")



let initialPayLoad={
  "filters":{
      "searchInput":"" 
  },
  "admin_id":adminId
}
const [adminPayload,setPayload]=useState(initialPayLoad)
const [adminList,setAdminList]=useState([])
const [searchInput,setSearchInput]=useState("")


const [adminAccess, setAdminAccess] = useState({});
const [adminDetails, setAdminDetails] = useState({});
const [isAdminLoading,seAdmintLoading]=useState(false)
const [isNetworkError,setError]=useState(false)
const accesstoken=localStorage.getItem("accesstoken")


useEffect(()=>{
fetchAdminAccess()
},[])

const fetchAdminAccess=()=>{
getAdminProfileDetails(seAdmintLoading,accesstoken,adminId,setAdminDetails,setAdminAccess,setError)
}


const getAdminList=()=>{
 FetchAdminList(setLoading,accesstoken,adminPayload,setAdminList)
}

const debounce = useDebounce(); 
const handleDebounceSearch=debounce((value)=>{
 
  setPayload((prev) => ({
    ...prev,
    filters: {
      searchInput: value, 
    },
  }));
},1000)

const handleSearchChange = (e) => {
  const value = e.target.value;
 
  setSearchInput(value); 
  handleDebounceSearch(value); 
};
useEffect(()=>{
  getAdminList()
},[adminPayload])


  return (
    <AdminContainer pageTitle="Admin Management" subtitle="Admin Management">


<div className='container font-nunito'>
         


            
         <div className=' flex flex-row justify-between gap-2' >

         <div className='flex flex-col w-full' >



         <div className='flex flex-row w-full justify-between p-4 gap-7 rounded-[10px] bg-gray2'>


         {/* searchbar */}
         <div className='flex flex-row gap-[40px]'>
         
           <div>
           <div className="relative text-gray-600">
       
           <button type="submit" className="absolute left-0 top-0 mt-3 ml-4">
                   <IoIosSearch className="h-4 w-4 fill-current" />
                 </button>
                 <input
                   type="search"
                   name="search"
                    value={searchInput}
                    onChange={handleSearchChange}
                   placeholder="Search By Admin Name,Email,Mobile No."
                   className="bg-white h-10  px-5 pl-10 rounded-2xl text-sm focus:outline-none"
                 style={{width:"330px"}}
                 />
                 
               </div>
               
           </div>
         </div>

       
         
         <div className='flex gap-5'> 
                

<Link to="/admin/create">
<button  disabled={adminAccess?.add_admin==0} 
className={`${adminAccess?.add_admin==0?'bg-lightgray text-black':'bg-pink text-white'} rounded-lg flex gap-2.5 py-2.5 px-4 items-start justify-center `}>
               
                 <p className='text-sm font-bold font-nunito'>+ Add Admin</p>
                 </button>
</Link>
                 


              

              
         
       
         </div>
   </div>




  

          
         </div>


         
       </div>


       
     </div>





    



     {isLoading ? (
     <div className="LoaderSection" > 
            
    
     <img src={loader} alt=""  className='loaderCls' />
     </div>


      ) : adminList.length === 0  ? (
        searchInput === '' ? (
          <div className='noPropContainer'>
            <div className='noPropImg'></div>
            <p className='noPropText text-gray'>No Admin is added yet</p>
          </div>
        ) : (
          <div className='noPropContainer'>
             <div className='noPropImg'></div>
            <p className='noPropText text-gray'>No Admin found.</p>
          </div>
        )
      ) : (
        <div className='adminList w-full  p-3 pt-5 gap-5'>
        {
     adminList &&  adminList.map((el, index) => (
          <AdminCard  adminAccess={adminAccess} {...el} index={index}  getAdminList={getAdminList}  />
        ))
        
        }
        </div>
      )}


{/* <div className='adminList w-full  p-3 pt-5 gap-5'>


<AdminCard/>


</div> */}

        </AdminContainer>
  )
}
