import React, { useEffect, useState } from 'react'
import AdminContainer from '../AdminContainer/AdminContainer'
import { MdOutlineFileDownload, MdOutlineKeyboardArrowLeft, MdOutlineKeyboardArrowRight } from 'react-icons/md'
import { IoIosClose, IoIosSearch } from 'react-icons/io'
import "./property-style.css"
import "./property-filter.css"
import { BiDotsHorizontalRounded } from 'react-icons/bi'

import PropertyCard from './PropertyCard'
import PropertyFilter from './PropertyFilter'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { ApiBaseUrl } from '../../Utils/urls'
import { usePropertyFilter } from '../../Utils/PropertyFilter'
import useDebounce from '../../Utils/Debounce'
import { BeatLoader } from 'react-spinners'
import { ISODate } from '../../Utils/ISODate'
import PropertySort from './PropertySort'
import ReactPaginate from 'react-paginate'
import { getAdminProfileDetails } from '../../Api/AdminApi'
import FloatingLoader from '../Loader/FloatingLoader'



export default function Property() {

  const { PropFilters, setPropFilters,setPage } = usePropertyFilter();
const [showPropertyFilter,setShowPropertyFilter]=useState(false)
const [showPropertySort,setShowPropertySort]=useState(false)
const [isLoading,setLoading]=useState(false)
const [respProperty,setResProperty]=useState([])
const [searchinput,setSearchInput]=useState("")
const [toDate,setToDate]=useState("")
const [fromDate,setFromDate]=useState("")
const [filtersChanged, setFiltersChanged] = useState(false);
const [toTalPages,setTotalPages]=useState(0)
const accestoken=localStorage.getItem("accesstoken")
const adminId=localStorage.getItem("adminId")


const [adminAccess, setAdminAccess] = useState({});
 
  const [isAdminLoading,seAdmintLoading]=useState(false)
  const [isNetworkError,setError]=useState(false)
 


useEffect(()=>{
  fetchAdminAccess()
},[])

const fetchAdminAccess=()=>{
  getAdminProfileDetails(
    seAdmintLoading,
    accestoken,
    adminId,
    undefined,
    setAdminAccess,
    setError)
}


const navigate=useNavigate()
const location=useLocation()

const debounce = useDebounce(); 
const handleShowpropertyFilter=()=>{
  setShowPropertyFilter(true)
}

const handleShowpropertySortPopUp=()=>{
  setShowPropertySort(true)
}
const handleDebounceSearch=debounce((value)=>{
     
  setFiltersChanged(true)
  setPropFilters((prev) => ({
    ...prev,
    filters: {
      ...prev.filters,
      searchInput: value, 
    },
  }));
},1000)

const handleSearchChange = (e) => {
  const value = e.target.value;
 
  setSearchInput(value); 
  handleDebounceSearch(value); 
};



useEffect(() => {
  const fetchData = async () => {
    try {
      setLoading(true);
      const pageToFetch = filtersChanged ? 1 : initialPage;
      const response = await fetch(`${ApiBaseUrl}/fr-admin/get-properties`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization':accestoken
        },
        body: JSON.stringify({
          ...PropFilters,
          admin_id: adminId,
          page:pageToFetch,
        }),
      });
      const responseData = await response.json();
      if (responseData.success) {
        setLoading(false);
        setResProperty(responseData.data.properties)
        setTotalPages(responseData.data.total_pages)
       } else {
        console.error('Error fetching data:', responseData.error);
      }
    } catch (error) {
      setLoading(false);
      console.error('Error fetching data:', error);
    }
  };
  fetchData();
}, [PropFilters]);



const handleFromChange = (e) => {
  const newFromDate = e.target.value;

  if (newFromDate) {
    const isoFormattedDate = ISODate(newFromDate);

    setFromDate(newFromDate);

    setPropFilters((prev) => ({
      ...prev,
      filters: {
        ...prev.filters,
        listing_date: {
          ...prev.filters.listing_date,
          from: isoFormattedDate,
        },
      },
    }));
  }
};

const handleToChange=(e)=>{
  setToDate(e.target.value)
  
  setPropFilters((prev) => ({
    ...prev,
    filters: {
      ...prev.filters,
      listing_date: {
        ...prev.filters.listing_date,
        to:ISODate(e.target.value)
      }, 
    },
  }));
  }


  const queryParams = new URLSearchParams(location.search);
  const initialPage = parseInt(queryParams.get('page')) || 1;
useEffect(() => {
  setPage(initialPage); 
  navigate(`/property?page=${initialPage}`)
}, [initialPage]);
const [currentPage, setCurrentPage] = useState(initialPage);

const handlePageChange = (data) => {
  setFiltersChanged(false);

  if (currentPage === 1 && data.selected === 0) {
    // Prevent navigation if already on the first page
    return;
  }
  const newPage = data.selected + 1; 
  
  setPage(newPage); 

  setCurrentPage(newPage);
  
    // Update the current page
    navigate(`/property?page=${newPage}`); 
   
};

const handleClearDate=()=>{
 // setFiltersChanged(true)
 setToDate("")
 setFromDate("")
  setPropFilters((prev) => ({
    ...prev,
    filters: {
      ...prev.filters,
      listing_date:{from: "", to: ""}
    },
  }));
}

const isClearButtonenabled=toDate!=="" && fromDate!==""


  return (
    <AdminContainer 
    pageTitle="Property Management" 
    subtitle="Property Management /" 
    >
      {
        isAdminLoading && (
          <FloatingLoader/>
        )
      }
      

<div className='container font-nunito'>
         


            
         <div className=' flex flex-row justify-between gap-2' >

         <div className='flex flex-col w-full' >



         <div className='flex flex-row w-full justify-between p-4 gap-7 rounded-[10px] bg-gray2'>


         {/* searchbar */}
         <div className='flex flex-row gap-[40px]'>
         
           <div>
           <div className="relative text-gray-600">
       
           <button type="submit" className="absolute left-0 top-0 mt-3 ml-4">
                   <IoIosSearch className="h-4 w-4 fill-current" />
                 </button>
                 <input
                   type="search"
                   name="search"
                   value={searchinput}
                   onChange={handleSearchChange}
                   placeholder="Search By Property Name,Price"
                   className="bg-white h-10  px-5 pl-10 propSearchBar rounded-2xl text-sm focus:outline-none"
                 />
                 
               </div>
               
           </div>
         </div>

       
         
         <div className='flex gap-5'> 
                
                    {/* <button>
                        <div className='flex gap-1 text-gray justify-center items-center'>
                            <p className='text-xs'>Download Report </p>
                            <MdOutlineFileDownload />
                        </div>
                    </button> */}

<Link to="/property/add">
<button disabled={!adminAccess?.create_new_property
} 
className={` ${adminAccess?.create_new_property
  ?'bg-pink text-white':'bg-lightgray text-gray'}  rounded-lg flex gap-2.5 py-2.5 px-4 items-start justify-center `}>
               
                 <p className='text-sm font-bold font-nunito'>+ Add Property</p>
                 </button>
</Link>
                 


                 <button className='bg-white rounded-lg flex  py-2 px-4 items-center justify-center text-black text-sm'>
                 
                 <input type="date" name="" id=""  value={fromDate}  onChange={handleFromChange}/>
               
                 </button>


                 <button className='bg-white rounded-lg flex  py-2 px-4 items-center justify-center text-black text-sm'>
                
                 <input type="date" name="" id="" value={toDate} onChange={handleToChange} />
                 </button>
         <button onClick={handleClearDate} 
         disabled={!isClearButtonenabled}
         className={`${isClearButtonenabled?'bg-pink text-white':'bg-lightgray text-gray'}   rounded-lg flex  py-2 px-2 items-center justify-center text-sm`}>
          Clear 
          <IoIosClose  className='text-2xl'/>
          
          </button>
         
       
         </div>
   </div>




{/* property-table */}
      <div className="AllPropContainer">


{/* filter */}

<PropertyFilter 
setShowPropertyFilter={setShowPropertyFilter} 
showPropertyFilter={showPropertyFilter}
/>


{/* filter end */}


{/* sorting popup */}

<PropertySort 
setShowPropertySort={setShowPropertySort} 
showPropertySort={showPropertySort}
/>


{/* sorting popup end */}



{/* filter-section */}
<div className='flex justify-between py-4'>
  <div>
   
  </div>


<div className='filtericon flex gap-3'>
<span className="material-symbols-outlined cursor-pointer"  onClick={handleShowpropertySortPopUp}>
sort
</span>
<span className="material-symbols-outlined cursor-pointer" onClick={handleShowpropertyFilter}>
filter_alt
</span>
</div>

  
</div>

{/* filter-section end */}


 {/* table-head */}
       <div className='flex w-full gap-2 justify-center text-center tableHead pt-3 pb-2 py-2'>
<div className='slNo'>
No.
</div>

<div className='ListingDateCls'>
Listing Date
</div>
<div className='propIdcls'>
Property ID
</div>
<div className='propNameCls'>
Property Name
</div>
<div className='totalTokenCls'>
Total Tokens
</div>
<div className='tokenPriceCls'>
Token Price
</div>
<div className='propPriceCls'>
Property Price
</div>
<div className='propPhraseCls'>
Property Phase
</div>
<div className='saleTypeCls'>
Sale Type.
</div>

<div className='actionCls'>
<BiDotsHorizontalRounded />
</div>




</div>

{/* table-head end */}


<hr className='prophrClass' />
            

{/* all properties  */}




{isLoading ? (
    <div className="alertBar" style={{height:"100vh"}}> 
            
    <BeatLoader/>
    </div>
      ) : respProperty.length === 0  ? (
        searchinput === '' ? (
          <div className='noPropContainer'>
            <div className='noPropImg'></div>
            <p className='noPropText text-gray'>No property is available</p>
          </div>
        ) : (
          <div className='noPropContainer'>
             <div className='noPropImg'></div>
            <p className='noPropText text-gray'>No property found.</p>
          </div>
        )
      ) : (
        <div className='propertyContainer'>
        {
        respProperty.map((property, index) => (
          <PropertyCard  {...property} key={index} index={index}
          adminAccess={adminAccess}
          />
        ))
        
        }
        </div>
      )}



<div className="pagination-container">
<ReactPaginate
              previousLabel={<MdOutlineKeyboardArrowLeft/>}
              nextLabel={<MdOutlineKeyboardArrowRight />}
              breakLabel={'...'}
              breakClassName={'pagination-break'}
              pageRangeDisplayed={3}
              marginPagesDisplayed={2}
              pageCount={toTalPages}
              pageClassName={`pagination-button`}
              onPageChange={handlePageChange}
              forcePage={currentPage-1}
              containerClassName={'pagination flex justify-end items-center gap-1.5'} 
              activeClassName={'pagination-active border border-pink text-black  rounded'} 
              previousClassName={`pagination-prev ${
                currentPage === 1 ? 'pageinateDisable ' : ''
              }`} 
              nextClassName={'pagination-prev '} 
            />




        </div>


  {/* all properties end */} 

</div>

  

          
         </div>


         
       </div>


       
     </div>


        </AdminContainer>
  )
}
