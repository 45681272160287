const { ApiBaseUrl } = require("../Utils/urls");

export const FetchAdminList=(
    setLoading,
    accessToken,
    adminPayload,
    setAdminList)=>{

    setLoading(true)
    fetch(`${ApiBaseUrl}/fr-admin/get-admins`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': accessToken
      },
      body: JSON.stringify(adminPayload)
    }
  )
      .then(response => {
      
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
       
        return response.json();
      })
      .then(resdata => {
       
       //console.log('Response from server:', resdata);
       setLoading(false)
          if(resdata.success)
          {
            setLoading &&  setLoading(false)
            setAdminList && setAdminList(resdata.data.admins)
          }
  
      })
      .catch(error => {
       
        console.error('error details:', error);
      });
  }



  export const getAdminProfileDetails=(
    seAdmintLoading,
    accessToken,
    adminId,
    setAdminDetails,
    setAdminAccess,
    setError,
    setTokenExpired
  )=>{
    seAdmintLoading &&   seAdmintLoading(true)
    fetch(`${ApiBaseUrl}/fr-admin/fetch-admin-profile`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': accessToken
      },
      body: JSON.stringify({
       
        "admin_id":adminId
      })
    }
  )
      .then(response => {
      
       
       
        return response.json();
      })
      .then(resdata => {
       
       //console.log('Response from server:', resdata);
       seAdmintLoading &&  seAdmintLoading(false)
          if(resdata.success)
            
          {
            
            setAdminDetails &&  setAdminDetails(resdata.data.adminDetails)
            setAdminAccess &&  setAdminAccess(resdata.data.adminDetails.permissions)
           
          }
          else if(!resdata.success && resdata.data==="Token has expired")
            {
              setTokenExpired && setTokenExpired(true)
            }
  
      })
      .catch(error => {
       
        console.error('error details:', error);
        setError(true)
      });
}
