import React,{useState,useEffect,useRef} from 'react'
import "./overview-style.css"
import AdminContainer from '../AdminContainer/AdminContainer'
import { FaArrowDown, FaArrowUp } from "react-icons/fa";
import{ Chart as ChartsJS, ArcElement, Tooltip, Legend} from 'chart.js'
import {Doughnut} from 'react-chartjs-2';

import loader from "../../Assets/Icons/Loader.gif"
import { IoIosSearch } from "react-icons/io";
import { MdOutlineKeyboardArrowLeft, MdOutlineKeyboardArrowRight, MdSort } from "react-icons/md";
import { MdOutlineFilterAlt } from "react-icons/md";
import OverviewSort from './OverviewSort';
import { BsThreeDots } from "react-icons/bs";
import OverviewCard from '../Cards/OverviewCard/OverviewCard';
import OverviewFilter from './OverviewFilter';

import { ApiBaseUrl } from '../../Utils/urls';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import ReactPaginate from 'react-paginate';
import useDebounce from '../../Utils/Debounce';


ChartsJS.register(
ArcElement,
Tooltip,
Legend
);

export default function Overview() {
  const [adminId, setAdminId] = useState("");
  const [accesstoken, setAccesstoken] = useState("");
  const [dataNew, setDataNew] = useState("");
  const [overviewData, setOverviewData] = useState([]);
  const [revenueData, setrevenueData] = useState({
    current_period_revenue: {
      primary_sale_revenue: 0,
      resale_revenue: 0,
    },
    primary_sale_growth: 0,
    resale_growth: 0,
  });
  const [isLoading,setLoading]=useState(false)
  const [isLoadingOther, setisLoadingOther] = useState(false);
  const [searchInput, setSearchInput] = useState('');
  const [showTransactionSort,setShowTransactionSort]=useState(false);
  const [isOpenMob, setIsOpenMob] = useState(false);
  const [sortSoldShares, setsortSoldShares] = useState("");
  const [sortTokenSaleAmount, setsortTokenSaleAmount] = useState("");
  const [propertyPhase, setpropertyPhase] = useState([]);
  const [propertyType,setPropertyType]=useState([])

  const [propertyData, setPropertyData] = useState([]);
  const debounceTimeout = useRef(null);
  const [responseData, setResponseData] = useState([]);

  const data = {
    labels: ['Verified', 'Review','Rejected','Pending'],
    datasets: [{
      data: [dataNew.kycVerified, dataNew.kycInReview,dataNew.kycRejected,dataNew.kycPending],
      backgroundColor: ['#0AB396', '#A3D0EB','#F2AEA1','#F5DBB1'],
      borderColor: ['#0AB396', '#A3D0EB','#F2AEA1','#F5DBB1'],
    }]
  };

  const formatDate = (date, time = '00:00:00') => {
    if (!date) return '';
    return (
      date.getFullYear() +
      '-' +
      String(date.getMonth() + 1).padStart(2, '0') +
      '-' +
      String(date.getDate()).padStart(2, '0') +
      ' ' +
      time
    );
  };
 
  const currentDate = new Date(); //setDateRangetoday
  let endDate
  endDate = new Date(currentDate);
  endDate.setDate(currentDate.getDate() + 1);//tomorrow
  const [dateRangeSec, setDateRangeSec] = useState({ start: formatDate(currentDate,'00:00:00'), end: formatDate(endDate,'23:59:59') });
   

  const [chartdata,setchartData]=useState(true)
  useEffect(()=>{
    const firstFourData = data.datasets[0].data.slice(0, 4);
    const firstFourAllZero = firstFourData.every(value => value === undefined);
   
    setchartData(firstFourAllZero)
    console.log(firstFourData,firstFourAllZero)
  },[dateRangeSec])
 

  const [overviewSelectValue,setOverviewSelectValue]=useState("")
  const [dateParam,setDateParam]=useState("") //for user list page param

  //pagination code
  const [filtersChanged, setFiltersChanged] = useState(false);
const [toTalPages,setTotalPages]=useState(0)
const [kycdateRange, setKycDateRange] = useState({ start: null, end: null });


  useEffect(() => {
      const storedAdminId = localStorage.getItem('adminId');
      const storedAccesstoken = localStorage.getItem('accesstoken');
      
      setAdminId(storedAdminId);
      setAccesstoken(storedAccesstoken);
      setOverviewSelectValue("Past 7 Days") //t
    }, []);
    
    useEffect(() => {
      if (adminId && accesstoken) {
        setLoading(true);
        const url = `${ApiBaseUrl}/fr-admin/get-kyc-status-breakdown?admin_id=${adminId}`;
        
        fetch(url, {
          method: 'POST',
          headers: {
            'Authorization': accesstoken
          },
          body: JSON.stringify({
            //admin_id: adminId,
            start_date: dateRangeSec.start,
            end_date: dateRangeSec.end
          })
        })
        .then(response => response.json())
        .then(data => {
          if (data && data.data && data.data.kycVerified !== undefined) {
            setDataNew(data.data);
            setLoading(false);
          } else {
            
          }
        })
        .catch(error => {
          setLoading(false);
          //console.error('Error fetching data:', error);
        });
      }
    }, [dateRangeSec]);


  


 
  const options={
    
  }
  

  
 

  


  const [dateRange, setDateRange] = useState({ start: null, end: null });
   useEffect(() => {
    
    const currentDate = new Date();
    const startDate = new Date(currentDate);
    startDate.setDate(currentDate.getDate() - 6);

    setDateRange({
      start: formatDate(startDate,'00:00:00'),
      end: formatDate(currentDate,'23:59:59'),
    });
  }, []);

// useEffect(()=>{
// let datefromLs=localStorage.getItem("dateParam")
// if(datefromLs){
//   setDateParam(datefromLs)
//   setOverviewSelectValue(datefromLs)
// }
// else{
//   setDateParam('Past 7 Days')
//   setOverviewSelectValue("Past 7 Days")
//   localStorage.setItem("dateParam",'Past 7 Days')
// }

// },[])


useEffect(()=>{

  setDateParam('Past 7 Days')
  localStorage.setItem("dateParam",'Past 7 Days')
  },[])
  

  const handleDateRangeChange = (event) => {

    
    const value = event.target.value;
    localStorage.setItem("dateParam",value)
    setOverviewSelectValue(value)
    const currentDate = new Date();
   console.log(value)
    let startDate;
let endDate;

    setDateParam(value)
    localStorage.setItem("dateParam",value)
    switch (value) {
      case 'All Time':
       
        startDate = null;
        endDate = currentDate;
        break;
      case 'Today':
        startDate = currentDate;
        endDate = currentDate;
        break;
      case 'Yesterday':
        startDate = new Date(currentDate);
        startDate.setDate(currentDate.getDate() - 1);
        endDate = startDate;
        break;
      case 'Past 7 Days':
        startDate = new Date(currentDate);
        startDate.setDate(currentDate.getDate() - 6);
        endDate = currentDate;
        break;
      case 'Past 30 Days':
        startDate = new Date(currentDate);
        startDate.setDate(currentDate.getDate() - 29);
        endDate = currentDate;
        break;
      case 'Past 365 Days':
        startDate = new Date(currentDate);
        startDate.setDate(currentDate.getDate() - 364);
        endDate = currentDate;
        break;
      default:
        startDate = null;
    }

    setDateRange({
      start: formatDate(startDate,'00:00:00')||null,
      end: formatDate(endDate,'23:59:59'),
    });
  };

  
  const handleDateRangeChangeSec = (event) => {
    const value = event.target.value;
    const currentDate = new Date();
    let startDate;
    let endDate = currentDate; 

   
    
  
    switch (value) {
      case 'All Time':
        startDate = null;
        endDate = currentDate;
        break;
      case 'Today':
        
      startDate = currentDate;
      endDate = currentDate;
        break;
      case 'Yesterday':
       
        startDate = new Date(currentDate);
        startDate.setDate(currentDate.getDate() - 1);
        endDate = startDate;
        break;
      case 'Past 7 Days':
       
        startDate = new Date(currentDate);
        startDate.setDate(currentDate.getDate() - 6);
        endDate = currentDate;
        break;
      case 'Past 30 Days':
      
        startDate = new Date(currentDate);
        startDate.setDate(currentDate.getDate() - 29);
        endDate = currentDate;
        break;
      case 'Past 365 Days':
       
        startDate = new Date(currentDate);
        startDate.setDate(currentDate.getDate() - 364);
        endDate = currentDate;
        break;
      default:
        startDate = null;
    }


    setDateRangeSec({
      start: formatDate(startDate,'00:00:00'),
      end: formatDate(endDate,'23:59:59'),
    });
  };


  const fetchData = () => {
    if (adminId && accesstoken && dateRange) {
        // setLoading(true);
      fetch(`${ApiBaseUrl}/fr-admin/get-overview`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': accesstoken
        },
        body: JSON.stringify({
          admin_id: adminId,
          start_date: dateRange.start,
          end_date: dateRange.end
        })
      })
      .then(response => {
       
        return response.json();
      })
      .then(data => {
        if (data && data.data) {
          setOverviewData(data.data);
          // setLoading(false);

        } else {
          throw new Error('Unexpected data format');
        }
      })
      .catch(error => {
        // setLoading(false);
       // .error('Error fetching overview data:', error);
      });
    }
  };


  useEffect(() => {
    fetchData();
  }, [adminId, accesstoken,dateRange, dateRange.start, dateRange.end]);




  const fetchDataRevenue = () => {
    if (adminId && accesstoken && dateRangeSec.start && dateRangeSec.end) {
        // setLoading(true);
      fetch(`${ApiBaseUrl}/fr-admin/fetch-revenue-details`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': accesstoken
        },
        body: JSON.stringify({
          admin_id: adminId,
          start_date: dateRangeSec.start,
          end_date: dateRangeSec.end
        })
      })
      .then(response => {
       
        return response.json();
      })
      .then(data => {
        if (data && data.data) {
          setrevenueData(data.data);
          // setLoading(false);

        } else {
          throw new Error('Unexpected data format');
        }
      })
      .catch(error => {
        // setLoading(false);
        //console.error('Error fetching overview data:', error);
      });
    }
  };

  useEffect(() => {
    
    fetchDataRevenue();
  }, [adminId, accesstoken, dateRangeSec.start, dateRangeSec.end]);


 

  const handleShowTransSort=()=>{
    setShowTransactionSort(!showTransactionSort)
  }

  const togglePopup = () => {
    setIsOpenMob(!isOpenMob);
  };
const [propTransFilter,setPropTransFilter]=useState({
  admin_id: adminId,
  property_phase: propertyPhase,
  property_type:propertyType,
  sortBy: {
    sold_shares: sortSoldShares,
    token_sale_amount: sortTokenSaleAmount,
  },
  searchInput: "",
  page:1,
pageSize:5
})

  const navigate=useNavigate()
  const location=useLocation()


  const queryParams = new URLSearchParams(location.search);
  const initialPage = parseInt(queryParams.get('page')) || 1;
  const setPage = (page) => {
    setPropTransFilter((prev) => ({
      ...prev,
      page
    }));
  };
useEffect(() => {
  setPage(initialPage);
  navigate(`/dashboard?page=${initialPage}`)
}, [initialPage]);
const [currentPage, setCurrentPage] = useState(initialPage);
const debounce = useDebounce(); 
const handleDebounceSearch=debounce((value)=>{
     
  setFiltersChanged(true)
  setPropTransFilter((prev) => ({
    ...prev,
   searchInput: value, 
   }));
},1000)

const handleSearch = (e) => {
  const value = e.target.value;
 
  setSearchInput(value); 
  handleDebounceSearch(value); 
};

const handlePageChange = (data) => {
  setFiltersChanged(false);

  if (currentPage === 1 && data.selected === 0) {
    // Prevent navigation if already on the first page
    return;
  }
  const newPage = data.selected + 1; 
  
  setPage(newPage); 

  setCurrentPage(newPage);
  
    // Update the current page
    navigate(`/dashboard?page=${newPage}`); 
   
};


  useEffect(() => {
    const fetchPropertyTransactions = async () => {
      try {
        setisLoadingOther(true);
        const pageToFetch = filtersChanged ? 1 : initialPage;
        const response = await fetch(`${ApiBaseUrl}/fr-admin/fetch-property-transactions`, {
          method: 'POST',
          headers: {
            'Authorization': accesstoken
          },
          body: JSON.stringify({
            ...propTransFilter,
            admin_id: adminId,
            page:pageToFetch,
            property_phase: propertyPhase,
            property_type:propertyType,
            sortBy: {
              sold_shares: sortSoldShares,
              token_sale_amount: sortTokenSaleAmount,
            }
          }),
        });

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        const data = await response.json();
       // console.log('Response data:', data);
       // console.log('updated:', data);
       //setTotalPages(responseData.data.total_pages)
        if (Array.isArray(data.data.property_transactions)) {
          setPropertyData(data.data.property_transactions);
          setResponseData(data.data.property_transactions);
          setTotalPages(data.data.total_pages)
          setisLoadingOther(false);
        } else {
          //console.error('API response is not an array:', data);
        }
      } catch (error) {
        setisLoadingOther(false);
        //console.error('Fetch error:', error);
      }
    };

    fetchPropertyTransactions();
  }, [adminId, propertyPhase, sortSoldShares,propertyType, sortTokenSaleAmount, accesstoken,propTransFilter]);


  const handleSearchChange = (e) => {
    const value = e.target.value;
    setSearchInput(value); 
  };
  // useEffect(() => {
  //   const debouncedSearch = debounce((input) => {
  //     const overviewData = input.trim() === '' ? responseData : responseData.filter(overview =>
  //       `${overview.token_sale_amount} ${overview.sold_shares} ${overview.property_id} ${overview.property_name} ${overview.total_shares} ${overview.tokens_purchased_last_7_days} ${overview.sale_type}`
  //           .toLowerCase()
  //           .includes(input.trim().toLowerCase())
  //   );
    
  //     setPropertyData(overviewData);
  //   }, 300);

  //   if (searchInput !== '') {
  //     debouncedSearch(searchInput);
  //   } else {
  //     setPropertyData(responseData);
  //   }

  //   return () => {
  //     debouncedSearch.cancel();
  //   };
  // }, [searchInput, responseData]);

  const handleRedirecttoUserList= () => {
    let dateParamFromLs=localStorage.getItem("dateParam")
   
    
    navigate(`/user?page=1&fromPage=overview`);
  };

  


  

  return (
    <AdminContainer pageTitle="Dashboard" subtitle="Overview">

<div className='container font-nunito'>
         
<div className='flex p-6 border border-lightgray rounded-2xl flex-col gap-6'>


<div className='flex justify-between'>
<p className='text-2xl font-bold'>Overview</p>

<div className='flex gap-2.5 items-center'>
<span className="material-symbols-outlined text-gray">
calendar_month
</span>

<select name="dateRange" id="dateRange" className="selectClass" onChange={handleDateRangeChangeSec}>
      
        <option value="Today">Today</option>
        <option value="Past 7 Days">Past 7 Days</option>
        <option value="Yesterday">Yesterday</option>
        <option value="Past 30 Days">Past 30 Days</option>
        <option value="Past 365 Days">Past 365 Days</option>
</select>
</div>

</div>

<div className=' flex flex-row gap-[24px] '>


    <div className=' flex flex-col justify-between rounded-md pt-[16px] pl-[16px] pr-[16px] gap-[50px] bg-[#F8F9FA]' style={{width:"33%"}}>
      <div className='flex flex-row justify-start'>Total Primary Sale Revenue</div>
      <div className='flex flex-row justify-between'>
        <div className=' flex flex-col justify-end font-semibold text-[44px] text-[#D328A7]'>₹{parseFloat(revenueData.current_period_revenue?.primary_sale_revenue).toFixed(2)}</div>
        <div className=' flex flex-row pb-3'>
          <div className='flex flex-col justify-end pb-[5px]'><FaArrowUp style={{height:"10px",color:"#3A974C"}} /></div>
          <div className=' flex flex-col justify-end font-bold text-[12px] text-[#3A974C]'>{parseFloat(revenueData.primary_sale_growth).toFixed(2)}%</div>
        </div>
      </div>
    </div>
    <div className=' flex flex-col justify-between rounded-md pt-[16px] pl-[16px] pr-[16px] gap-[50px] bg-[#F8F9FA]' style={{width:"33%"}}>
      <div className='flex flex-row justify-start'>Total Resale Sale Revenue</div>
      <div className='flex flex-row justify-between'>
        <div className=' flex flex-col justify-end font-semibold text-[44px] text-[#D328A7]'>₹{parseFloat(revenueData.current_period_revenue?.resale_revenue).toFixed(2)}</div>
        <div className=' flex flex-row pb-3'>
          <div className='flex flex-col justify-end pb-[5px]'><FaArrowUp style={{height:"10px",color:"#3A974C"}} /></div>
          <div className=' flex flex-col justify-end font-bold text-[12px] text-[#3A974C]'>{parseFloat(revenueData.resale_growth).toFixed(2)}%</div>
        </div>
      </div>
    </div>
 
</div>




</div>

<div className='flex flex-row justify-between gap-[23px] rounded-2xl border border-lightgray mt-[20px]'>
    

<div className=' flex flex-col gap-[38px] p-[19px] ' style={{width:"48%"}}>

<div className=' flex flex-col gap-[38px] bg-[#F4F3F5] rounded-xl py-[27px] px-[18px] h-[430px]'>
  <div className='flex flex-row justify-between'>
      <p className='text-2xl font-bold'> KYC Status Breakdown</p>

      <div className='flex items-center'>

           
            </div>
  </div>
  
  <div className='flex flex-col' style={{height:"100%"}}>


  <div className=' flex flex-row  justify-between' style={{height:"100%"}}>
  {isLoading ? (
    <div className='flex flex-col justify-center'>
      <img src={loader} alt="" srcSet="" className='loaderCls' />
    </div>
  ) : (
    <div className='flex flex-col justify-center chartSizeHelper'>
      {chartdata ? (
        <div className='text-center'>No data available</div>
      ) : (
        <Doughnut data={data} options={options} />
      )}
    </div>
  )}
      <div className=' flex flex-col justify-between' style={{width:"50%"}}>
        <div className=' flex flex-row justify-between'>
          <div className=' flex flex-row gap-[8px]'>
            <div className='text-[13px] font-normal pr-2 text-start'>Number of User&apos;s KYC Verified</div>
          </div>
          <div className=' text-[21px] font-semibold'>{dataNew.kycVerified}</div>
          </div>
         <div className=' flex flex-row justify-between'>
          <div className=' flex flex-row gap-[8px]'>
            <div className='text-[13px] font-normal pr-2 text-start'>Number of User&apos;s
            KYC In Review</div>
          </div>
          <div className=' text-[21px] font-semibold'>{dataNew.kycInReview}</div>
          </div>
         <div className=' flex flex-row justify-between'>
          <div className=' flex flex-row gap-[8px]'>
            <div className='text-[13px] font-normal pr-2 text-start'>Number of User&apos;s 
            KYC Rejected</div>
          </div>
          <div className=' text-[21px] font-semibold'>{dataNew.kycRejected}</div>
          </div>
         <div className=' flex flex-row justify-between'>
          <div className=' flex flex-row gap-[8px]'>
            <div className='text-[13px] font-normal pr-2 text-start'>Number of User&apos;s 
            KYC Pending</div>
          </div>
          <div className=' text-[21px] font-semibold'>{dataNew.kycPending}</div>
          </div>
      </div>
  </div>

  </div>
</div>





</div>


    <div className=' flex flex-col gap-[38px] p-[19px] ' style={{width:"48%"}}>

      <div className=' flex flex-col gap-[38px] bg-[#F4F3F5] rounded-xl py-[27px] px-[18px] h-[430px]'>
        <div className='flex flex-row justify-between'>
            <p className='text-2xl font-bold'>Overview</p>

            <div className='flex items-center'>

            <select name="dateRange" id="dateRange" value={overviewSelectValue} className="selectClass" onChange={handleDateRangeChange}>
                    <option value="All Time">All Time</option>
                    
                    <option value="Today">Today</option>
                    <option value="Yesterday">Yesterday</option>
                    <option value="Past 7 Days">Past 7 Days</option>
                   
                    <option value="Past 30 Days">Past 30 Days</option>
                    <option value="Past 365 Days">Past 365 Days</option>
            </select>
            </div>

        </div>
        
        <div className='flex flex-col justify-center' style={{height:"100%"}}>
          <div className='flex flex-col'>

          <div className='flex flex-row justify-center'>
            <div className=' flex flex-row justify-between gap-[10px]' style={{width:"90%"}}>
              <div className=' flex flex-col justify-between rounded-md pt-[16px] pl-[16px] pr-[16px] bg-[#E8EFFB] border border-[#6691E7]' style={{width:"50%"}}>
              <div className='flex flex-row justify-start text-[14px] font-normal'>Total Token Sales</div>
              <div className='flex flex-row justify-between'>
                <div className=' flex flex-col justify-end font-semibold text-[44px] text-[#6691E7]'>{overviewData?.totalTokenSales}</div>
                <div className=' flex flex-row pb-3'>
                  <div className='flex flex-col justify-end pb-[5px]'>
                    {
                      overviewData?.tokenSalesGrowthPercentage <0 ?
                      <FaArrowDown style={{height:"10px"}} />
                      :
                      <FaArrowUp style={{height:"10px"}} />
                    }
                   
                    
                    </div>
                  <div className=' flex flex-col justify-end font-bold text-[12px]'>{overviewData?.tokenSalesGrowthPercentage}%</div>
                </div>
              </div>
              </div>

              <div onClick={handleRedirecttoUserList} className='cursor-pointer flex flex-col justify-between rounded-md pt-[16px] pl-[16px] pr-[16px] bg-[#FCF5E5] border border-[#E8BC52]' style={{width:"50%"}}>
                <div className='flex flex-row justify-start text-[14px] font-normal'>Total number of users</div>
                <div className='flex flex-row justify-between'>
                  <div className=' flex flex-col justify-end font-semibold text-[44px] text-[#E8BC52]'>{overviewData?.totalNumberOfUsers}</div>
                  <div className=' flex flex-row pb-3'>
                    <div className='flex flex-col justify-end pb-[5px]'>
                      {
                        overviewData?.userGrowthPercentage <0 ?
                          <FaArrowDown style={{height:"10px"}} />
                          :
                          <FaArrowUp style={{height:"10px"}} />
                      }
                     
                      
                      </div>
                    <div className=' flex flex-col justify-end font-bold text-[12px]'>{overviewData.userGrowthPercentage}%</div>
                  </div>
                </div>
              </div>

            </div>
          </div>

          
          <div className='flex flex-row justify-center mt-[20px]'>
            <div className=' flex flex-row justify-between gap-[10px]' style={{width:"90%"}}>
              <div className=' flex flex-col justify-between rounded-md pt-[16px] pl-[16px] pr-[16px] bg-[#E5F6FB] border border-[#50C3E6]' style={{width:"50%"}}>
              <div className='flex flex-row justify-start text-[14px] font-normal'>Total Properties</div>
              <div className='flex flex-row justify-between'>
                <div className=' flex flex-col justify-end font-semibold text-[44px] text-[#50C3E6]'>{overviewData.totalProperties}</div>
              </div>
              </div>

              <div className=' flex flex-col justify-between rounded-md pt-[16px] pl-[16px] pr-[16px] bg-[#DCF6E9] border border-[#13C56B]' style={{width:"50%"}}>
                <div className='flex flex-row justify-start text-[14px] font-normal'>Total transaction Volume</div>
                <div className='flex flex-row justify-between'>
                <div className='flex flex-col justify-end font-semibold text-[44px] text-[#13C56B]'>
                {overviewData.totalTransactionVolume ? (parseFloat(overviewData.totalTransactionVolume) / 100000).toFixed(2) + " Lac" : ""}
              </div>
                </div>
              </div>

            </div>
          </div>

          </div>
        </div>

      </div>





    </div>


</div>

         
<div className='flex p-6 border border-lightgray rounded-2xl flex-col gap-6 bg-[#F8F9FA] mt-[20px]'>


<div className='flex justify-between'>
<p className='text-2xl font-bold'>Property Transaction Details</p>

<div className="flex flex-row rounded-xl bg-white">
<div type="submit" className="left-0 top-0 mt-3 ml-4">
  <IoIosSearch/>
</div>
<input
  type="search"
  name="search"
  value={searchInput}
  onChange={handleSearch}
  placeholder="Search By Property Name, ID etc."
  className="bg-white h-10 px-5 rounded-2xl text-sm focus:outline-none w-80"
/>
</div>

</div>

<div className=' flex flex-row justify-end gap-[36px]'>

<div className='flex flex-row justify-end gap-3'>
<div><MdSort style={{height:"25px",width:"25px"}} onClick={handleShowTransSort} className='cursor-pointer' /></div>
<div><MdOutlineFilterAlt  onClick={togglePopup} style={{height:"25px",width:"25px"}}  className='cursor-pointer'/></div>
</div>
</div>

<div className={ `rounded-xl popup-containerBookedFilter  ${isOpenMob ? 'active' : ''}`} style={{ boxShadow: '0px 4px 30px 0px #00000026' }}>
            <OverviewFilter 
            setIsOpenMob={setIsOpenMob} 
            propertyPhase={propertyPhase} 
            setpropertyPhase={setpropertyPhase}
            propertyType={propertyType}
            setPropertyType={setPropertyType}
            />
           </div>

       
<OverviewSort 
setShowTransactionSort={setShowTransactionSort}
 showTransactionSort={showTransactionSort} 
 sortSoldShares={sortSoldShares} 
 setsortSoldShares={setsortSoldShares}
setsortTokenSaleAmount={setsortTokenSaleAmount} 
sortTokenSaleAmount={sortTokenSaleAmount} />

<div className='flex flex-row justify-between pr-[20px] '>
  <div className=' text-[12px] font-normal w-[30px]'>No.</div>
  <div className=' text-[12px] font-normal w-[340px]'>Property Name</div>
  <div className=' text-[12px] font-normal w-[100px]'>Total Tokens</div>
  <div className=' text-[12px] font-normal w-[100px]'>Token Sale Amount</div>
  <div className=' text-[12px] font-normal w-[100px]'>Phase Type</div>
  <div className=' text-[12px] font-normal' style={{display:"none"}}><BsThreeDots style={{height:"25px",width:"25px",color:"#838383"}} /></div>
</div>

<div className='-mt-[10px]'><hr></hr></div>

<div className=''>

{isLoading ? (
  <div className='flex flex-row justify-center '>
    <img src={loader} alt="" srcSet="" className='loaderCls' />
  </div>
) :
propertyData.length===0?
<p>No Properties Found</p>:

(
  <div>
    {propertyData.map((item, index) => (
      <OverviewCard 
        key={index} 
        item={item} 
        serialNo={index + 1}
        property_phase={item.property_phase}
        propertyName={item.property_name}
        totalShares={item.total_shares}
        soldShares={item.sold_shares}
        tokenSaleAmount={item.token_sale_amount}
        tokensPurchasedLast7Days={item.tokens_purchased_last_7_days}
        saleType={item.sale_type}
      />
    ))}
  </div>
)}

</div>
<div className="pagination-container">
    <ReactPaginate
              previousLabel={<MdOutlineKeyboardArrowLeft/>}
              nextLabel={<MdOutlineKeyboardArrowRight />}
              breakLabel={'...'}
              breakClassName={'pagination-break'}
              pageRangeDisplayed={3}
              marginPagesDisplayed={2}
              pageCount={toTalPages}
              pageClassName={`pagination-button`}
              onPageChange={handlePageChange}
              forcePage={currentPage-1}
              containerClassName={'pagination flex justify-end items-center gap-1.5'} 
              activeClassName={'pagination-active border border-pink text-black  rounded'} 
              previousClassName={`pagination-prev ${
                currentPage === 1 ? 'pageinateDisable ' : ''
              }`} 
              nextClassName={'pagination-prev '} 
            />




        </div>


</div>









  </div>



        </AdminContainer>
  )
}
