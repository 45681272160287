import React, { useEffect, useState } from 'react';
import { MdKeyboardArrowDown } from "react-icons/md";
import { IoHome } from "react-icons/io5";
import logo from "../../Assets/logo/logo2.svg";
import './sidebar.css';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import userIcon from "../../Assets/sidebarIcon/user_managemnt_icon.svg"
import propIcon from "../../Assets/sidebarIcon/property_icon.svg"
import financeIcon from "../../Assets/sidebarIcon/finance_icon.svg"
import adminIcon from "../../Assets/sidebarIcon/adminicon.svg"
import overviewIcon from "../../Assets/sidebarIcon/overview_icon.svg"

import { IoIosLogOut } from 'react-icons/io';
import { useAuth } from '../AuthContext/AuthContext';
import useAdminAccess from '../AdminAccessContext/Useadminaccess';
import { getAdminProfileDetails } from '../../Api/AdminApi';


const Dropdown = ({ title, children, Icon, onClick ,isDropDown,ImageIcon,IcImage,activeMenuItem,menuName}) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleClick = () => {
    setIsOpen(!isOpen); // Toggle dropdown visibility
    if (onClick) {
      onClick(); // Trigger the onClick function passed as prop
    }
  };



  return (
    <li className="opcion-con-desplegable font-nunito cursor-pointer">
      <div className="flex w-full items-center justify-between gap-2 p-2 bg-blue" onClick={handleClick}>
        <div className="flex items-center">
          {Icon && (
            <div className='w-7 h-6 rounded-md bg-pink flex justify-center items-center mr-2'>
              <Icon />
            </div>
          )}

{ImageIcon && (
            <div className='w-7 h-6 rounded-md bg-pink flex justify-center items-center mr-2'>
              <img src={IcImage} alt="" />
            </div>
          )}
          <p className={`${activeMenuItem===menuName?'text-pink':'text-white'} text-sm `}>{title}</p>
        </div>
        {
        isDropDown && (
          <MdKeyboardArrowDown />
        )

        }
      
      </div>
      <ul className={`desplegable ml-4 ${isOpen ? '' : 'hidden'}`}>
        {children}
      </ul>
    </li>
  );
};

export const SideNavbar = () => {
  const navigate = useNavigate();

  const navigateMe = (location) => {
    navigate(`/${location}`);
  };


  const {logout} = useAuth()

  const handlelogout=()=>{
    logout()
  }


  const [activeMenuItem, setActiveMenuItem] = useState(''); //active menu

//const {adminAccess}=useAdminAccess()
  const [adminAccess, setAdminAccess] = useState({});
  const [adminDetails, setAdminDetails] = useState({});
  const [isAdminLoading,seAdmintLoading]=useState(false)
  const [isNetworkError,setError]=useState(false)
  const accesstoken=localStorage.getItem("accesstoken")
  const adminId=localStorage.getItem("adminId")

useEffect(()=>{
  fetchAdminAccess()
},[])

const fetchAdminAccess=()=>{
  getAdminProfileDetails(seAdmintLoading,accesstoken,adminId,setAdminDetails,setAdminAccess,setError)
}


  useEffect(() => {
    const path = window.location.pathname;
    
    if (path.startsWith('/dashboard')) {
      setActiveMenuItem('dashboard');
     
    } else if (path.startsWith('/user')) {
      setActiveMenuItem('user');
      
    } else if (path.startsWith('/property')){
      setActiveMenuItem('property');
     
    }
   else if (path.startsWith('/transaction')){
    setActiveMenuItem('transaction');
    
  }
  else if (path.startsWith('/adminlist') || path.startsWith('/admin')){
    setActiveMenuItem('adminlist');
    
  }
    else{
      setActiveMenuItem('');
    
    }
  }, []);

  return (
    <aside className="bg-blue text-white   min-h-screen p-3 font-nunito absolute left-0 top-0 z-9999 flex h-screen  flex-col overflow-y-hidden duration-300 ease-linear lg:static lg:translate-x-0" style={{width:"230px"}}>
      <div className='logodiv py-4 flex flex-col gap-5'>
        <img src={logo} alt="" className='logoclass'/>
        <div className='logo-underline'></div>
      </div>
      <nav className='mt-6 overflow-y-auto no-scrollbar'>
        <ul className="space-y-2 ">

        <Dropdown title="Overview" activeMenuItem={activeMenuItem} menuName={'dashboard'} ImageIcon={true}  IcImage={overviewIcon} onClick={() => navigateMe("dashboard")} />
      
      {
        adminAccess?.view_user_profile==1 && (
          <Dropdown title="User Management" activeMenuItem={activeMenuItem} menuName={'user'} ImageIcon={true}  IcImage={userIcon} onClick={() => navigateMe("user")} />
        )
      }
      
       {
adminAccess?.view_property==1 && (
  <Dropdown title="Property Management" ImageIcon={true} activeMenuItem={activeMenuItem} menuName={'property'} IcImage={propIcon} onClick={adminAccess.view_property ? () => navigateMe("property") : null} />

)
        }
{
  adminAccess?.transactions_access==1 && (
    <Dropdown title="Finance Management"  ImageIcon={true} activeMenuItem={activeMenuItem} menuName={'transaction'} IcImage={financeIcon} onClick={() => navigateMe("transaction")} />
        
       
  )
}

         {
            adminAccess?.view_admin==1 && (
              <Dropdown title="Admin Management"  ImageIcon={true} activeMenuItem={activeMenuItem} menuName={'adminlist'} IcImage={adminIcon} onClick={() => navigateMe("adminlist")} />
       
            )
          }
       
        </ul>
      </nav>

      <button className='flex items-center justify-center gap-2 logoutBtn' onClick={handlelogout}>
        <IoIosLogOut /> 
        
        <p>Logout</p>
        </button>
    </aside>
  );
};
