import React, { useEffect } from 'react'
import AdminContainer from '../AdminContainer/AdminContainer'
import "./property-style.css"
import { FaRegCircleUser } from 'react-icons/fa6'
import Switch from 'react-switch'; 
import { useState } from 'react';
import PropertyInfo from './PropertyInfo';
import TokenInfo from './TokenInfo';
import PropertyInfoTab from './PropertyInfoTab';
import Amenities from './Amenities';
import Tenant from './Tenant';
import FloorPlans from './FloorPlans';
import { ApiBaseUrl } from '../../Utils/urls';
import { useParams } from 'react-router-dom';
import PropertyImage from './PropertyImage';
import TaxesCharges from './TaxesCharges';
import EditPropertyInfo from './EditProperty/EditPropertyInfo';
import EditPropertyImage from './EditProperty/EditPropertyImage';
import EditTokenInfo from './EditProperty/EditTokenInfo';
import EditTaxesCharges from './EditProperty/EditTaxesCharges';
import EditPropertyInfoTab from './EditProperty/EditPropertyInfoTab';
import EditTenant from './EditProperty/EditTenant';
import EditFloorPlans from './EditProperty/EditFloorPlans';
import ViewPropertyInfo from './ViewProperttyCompo/ViewPropertyInfo';
import ViewPropertyImage from './ViewProperttyCompo/ViewPropertyImage';
import ViewTokenInfo from './ViewProperttyCompo/ViewTokenInfo';
import ViewTaxesCharges from './ViewProperttyCompo/ViewTaxesCharges';
import ViewPropertyInfoTab from './ViewProperttyCompo/ViewPropertyInfoTab';
import ViewTenant from './ViewProperttyCompo/ViewTenant';
import ViewFloorPlans from './ViewProperttyCompo/ViewFloorPlans';
import ViewAmenities from './ViewProperttyCompo/ViewAmenities';

export default function ViewProperty() {
const {id:propertyId}=useParams()
const [isLoading,setLoading]=useState(false)
const [propRes,setPropResp]=useState({})
const [propertyinfo,setPropertyInfo]=useState({})


const accesstoken=localStorage.getItem("accesstoken")
const adminId=localStorage.getItem("adminId")
//const [propertyId,setPropertyId]=useState(null)
const [propStatus,setPropSatus]=useState(false)
  const fetchPropertyDetails=()=>{

    setLoading(true)
    fetch(`${ApiBaseUrl}/fr-admin/get-property-details?property_id=${propertyId}&admin_id=${adminId}`,{
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': accesstoken
      }
    })
      .then(response => {
      
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
       
        return response.json();
      })
      .then(resdata => {
       
       //console.log('Response from server:', resdata);
       
          if(resdata.success)
          {
            setLoading(false)
           setPropResp(resdata.data)
           setPropertyInfo(resdata.data.property_info)
           setPropSatus(resdata.data.property_info.is_active)
          }
  
      })
      .catch(error => {
       
        console.error('error details:', error);
      });
  }

  useEffect(()=>{
    fetchPropertyDetails()
  },[])

  return (
    <AdminContainer 
    pageTitle={`${propertyinfo?.property_name||"Property"}`} 
    subtitle="Property Management /" 
    backLinkPath="/property"
    propStatus={propStatus}
    propertyId={propertyId}
    showViewDeactivateButton={true}
    >



<div className='container font-nunito'>


  <div className='flex flex-col p-5 gap-2.5'>


{/* property primary details */}
<ViewPropertyInfo 
propertyId={propertyId} 
//setPropertyId={setPropertyId}
/>
{/* property primary details end*/}


<ViewPropertyImage/>

<ViewTokenInfo/>
<ViewTaxesCharges/>
<div className='propCard'>

<ViewPropertyInfoTab/>
<ViewAmenities/>

<hr  className='addhrClass'/>

<ViewTenant propertyinfo={propertyinfo}/>
<hr  className='addhrClass'/>
<ViewFloorPlans/>

</div>
  </div>



</div>



        
        </AdminContainer>
  )
}
