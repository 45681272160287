
import './App.css';
import AllRoutes from './Components/AllRoutes/AllRoutes';
import PopUp from './Components/PopUp/PopUp';
import { useEffect, useState } from 'react';
import { getAdminProfileDetails } from './Api/AdminApi';
import { useAuth } from './Components/AuthContext/AuthContext';
import { useLocation } from 'react-router-dom';


function App() {
  const accessToken = localStorage.getItem("accesstoken");
  const adminId = localStorage.getItem("adminId");
  const location = useLocation();
const [isTokenexpired,setTokenExpired]=useState(false)


const {logout} = useAuth()


  const handlelogout=()=>{
    logout()
    setTokenExpired(false)
  }



const fetchAdminDetails=()=>{
  getAdminProfileDetails(
    undefined,
    accessToken,
    adminId,
    undefined,
    undefined,
    undefined,
    setTokenExpired)
}

useEffect(()=>{

  if(accessToken)
    {
      fetchAdminDetails()
    }

},[location])
  return (
    <div className="App">
    <AllRoutes/>
   
   
    {isTokenexpired && (
      <PopUp  maxWidth={"max-w-md"}>
        
        <div className='flex flex-col gap-4'>
        <p className='text-2xl text-blue font-bold font-nunito'>Session Expired</p>
        <p className='text-lg text-gray  font-nunito'>Please log in again</p>

<div className='flex justify-center '>
<button className='bg-pink text-white w-1/3 rounded-md py-2' onClick={handlelogout}>Ok</button>
   
</div>
        </div>
    

      </PopUp>
    )

    }
    
    </div>
  );
}

export default App;
