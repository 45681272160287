import React, { useEffect, useState } from 'react'
import { Routes,Route } from "react-router-dom"

import UserManagement from '../UserManagement/UserManagement'
import Transaction from '../TransactionManagement/Transaction'
import Property from '../Property/Property'

import Login from '../Login/Login'
import SingleUser from '../UserManagement/SingleUser'

import PrivateRoute from './PrivateRoute'
import AdminList from '../AdminManagement/AdminList'
import AdminCreate from '../AdminManagement/CreateAdmin/AdminCreate'
import AdminEditPage from '../AdminManagement/EditAdmin/AdminEditPage'
import ProtectedRoute from './ProtectedRoute'
import Overview from '../Overview/Overview'
import EditProperty from '../Property/EditProperty'
import TransactionDetails from '../TransactionDetails/TransactionDetails'
import AddProperty from '../Property/AddProperty'
import ViewProperty from '../Property/ViewProperty'
import { getAdminProfileDetails } from '../../Api/AdminApi'

import AdminViewPage from '../AdminManagement/ViewAdmin/AdminViewPage'






export default function AllRoutes() {


  const [adminAccess, setAdminAccess] = useState({});
  const [isAdminLoading, setAdminLoading] = useState(false);
  const [isNetworkError, setError] = useState(false);
  const accestoken = localStorage.getItem("accesstoken");
  const adminId = localStorage.getItem("adminId");

  useEffect(() => {
    fetchAdminAccess();
  }, []);

  const fetchAdminAccess = () => {
    getAdminProfileDetails(
      setAdminLoading,
      accestoken,
      adminId,
      undefined,
      setAdminAccess,
      setError
    );
  };







  return (
    <Routes>
           <Route path="/" element={
            <ProtectedRoute>
           <Login/>

            </ProtectedRoute>
           
           } />
           
            <Route path="/transaction" element={
            <PrivateRoute>
                <Transaction/>
            </PrivateRoute>
            } />



{/* <Route path="/transaction" element={
        <PrivateRoute>
          <AccessControlRoute component={Transaction} requiredAccess="transactions_access" />
        </PrivateRoute>
      } /> */}




            <Route path="/user" element={
               <PrivateRoute>
                  <UserManagement/>
               </PrivateRoute>
            
            
            } />
            <Route path="/user/:id" element={<SingleUser/> } />


            <Route path="/property" element={<Property/>} />
            {/* <Route path="/property/add" element={<AddProperty/> } /> */}
            <Route path="/property/edit/:id" element={<EditProperty/> } />
            <Route path="/property/:id" element={<ViewProperty/> } />


       
  <Route path="/property/add" element={<AddProperty/> } />
            <Route path="/adminlist" element={<AdminList/> } />
            <Route path="/admin/create" element={<AdminCreate/> } />
            <Route path="/admin/edit/:id" element={<AdminEditPage/> } />
            <Route path="/admin/:id" element={<AdminViewPage/> } />

            <Route path="/dashboard" element={<Overview/> } />
            <Route path="/transaction/:id" element={<TransactionDetails/> } />
           
    </Routes>
  )
}
