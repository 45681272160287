import React, { useEffect, useState } from 'react'
import "../document-style.css"
import { getPropertyDetails } from '../../../Api/PropertyAddApi'
import { ApiBaseUrl, FileUploadURL } from '../../../Utils/urls'
import FloatingLoader from '../../Loader/FloatingLoader'
import SuccessAlert from '../../Alert/SuccessAlert'
import ErrorAlert from '../../Alert/ErrorAlert'
import EditDocCategoryCard from './EditDocCategoryCard'
import { useParams } from 'react-router-dom'



export default function EditDocumentUpload() {
    const accessToken=localStorage.getItem("accesstoken")
    const adminId=localStorage.getItem("adminId")
    const {id:propertyId}=useParams()
    const [pdfArray,setPdfArray]=useState([])
    const [isDocLoading,setDocLoading]=useState(false)
    const [isDocUpdated,setDocUpdated]=useState(false)
    const [isDocErr,setDocErr]=useState(false)
    const [alertMsg,setAlertMsg]=useState("")
    const [isLoading,setLoading]=useState(false)
const [documentsList,setDocumentsList]=useState([{
    "category": "",
    "documents": [
        {
            "docs": [],
            "title": "",
            "description": null
        }
    ]
}])


function extractDocs(array) {
    
    return array.map(item => item.documents.map(doc => doc.docs)).flat(2);
}





// console.log(pdfArray)

useEffect(()=>{
    if(documentsList)
        {
            let extractedpdf=extractDocs(documentsList)
            setPdfArray(extractedpdf)
        }
},[documentsList])


useEffect(()=>{

    if(propertyId)
        {
            fetchPropDetails()
        }
   
},[])

const fetchPropDetails=()=>{
    getPropertyDetails(
      setLoading,
      propertyId
      ,adminId,
      accessToken,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      setDocumentsList

    )
  }

  const addNewCategoryCard = () => {
    const newCategory = {
        "category": "",
        "documents": [
            {
                "docs": [],
                "title": "",
                "description": null
            }
        ]
    }
    setDocumentsList([...documentsList, newCategory])
}


//upload image to EC2
const handleDocumentUploadtoEc2=()=>{
    setDocLoading(true)
    fetch(`${FileUploadURL}/docs/upload`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': accessToken
      },
      body: JSON.stringify({
        "admin_id":adminId,
        "folder":"pdf",
        "property_id": propertyId,
        "property_documents": documentsList
       
    })
    }
  )
      .then(response => {
        return response.json();
      })
      .then(resdata => {
       
       console.log('Response from server:', resdata);
    
    
          if(resdata.success)
          {
         
            updateDocument(resdata.data.property_documents);

            // setUploadedImages([])
            
         
          }
          else if(!resdata.success)
             {
               
             }
  
      })
      .catch(error => {
       
        console.error('error details:', error);
      });
 
}


//update to Db
const updateDocument=(docArray)=>{
    fetch(`${ApiBaseUrl}/fr-admin/update-property-docs`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': accessToken
      },
      body: JSON.stringify({
        "admin_id":adminId,
    "property_id": propertyId,
    "property_documents":docArray
       
    })
    }
  )
      .then(response => {
        return response.json();
      })
      .then(resdata => {
       
        setDocLoading(false)
    
    
          if(resdata.success)
          {
            setDocUpdated(true)
            setAlertMsg(resdata.data)
            fetchPropDetails()

            setTimeout(() => {
                setDocUpdated(false)
            setAlertMsg("")
            }, 3000);
         
          }
          else if(!resdata.success)
             {
                setDocErr(true)
            setAlertMsg(resdata.data)
           

            setTimeout(() => {
                setDocErr(false)
            setAlertMsg("")
            }, 3000);
             }
  
      })
      .catch(error => {
       
        console.error('error details:', error);
      });
}

const isSaveBtnEnabled = documentsList.every(category => category.category && category.documents.every(doc => doc.title && doc.docs.length > 0));




  return (

<>




    <div className='flex flex-col gap-5 relative'>
 {
    isDocLoading && (
<FloatingLoader/>
    )
 }
 {
    isDocUpdated && (
        <SuccessAlert message={alertMsg} />
    )
 }

{
    isDocErr && (
        <ErrorAlert message={alertMsg} />
    )
 }

    <div className='flex justify-between items-center'>
      <p className='text-gray text-lg font-medium'>Documents</p>

      <div className='flex gap-5'>


      <button 
      className={`propSaveBtn  bg-pink text-white `}
    
    onClick={addNewCategoryCard}
      >
        Add Category
        </button>


      <button 
      className={`propSaveBtn  ${isSaveBtnEnabled?'bg-pink text-white':'bg-lightgray text-gray'} `}
      disabled={!isSaveBtnEnabled}
      onClick={handleDocumentUploadtoEc2}
      >
        Save Details</button>
      </div>
   
  </div>



<div className='flex gap-5 documentBox'>


    {
        documentsList && documentsList.map((el,index)=>(
            <EditDocCategoryCard
            key={index} 
            index={index} 
            {...el} 
            setDocumentsList={setDocumentsList} 
            documentsList={documentsList}
            setPdfArray={setPdfArray}
            pdfArray={pdfArray}
            />
        ))
    }


</div>



  </div>

  </>
  )
}
